.header {
    font-size: 40px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.form_container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}

.form_container input[type=file],
.form_container select {
    width: 100%;
    padding: 12px 20px;
    margin: 0 0 15px  0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
}

.form_container input[type=file]{
    cursor: pointer;
}
.form_container input[type=file]:hover {
    background-color: #ccc;
}

.form_container input[type=submit] {
    width: 100%;
    background-color: var(--color-primary-used-100);
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form_container input[type=submit]:hover {
    background-color: var(--color-primary-used-300);
}

/* Add animation to "page content" */
.animate_bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from {
        left: -100px;
        opacity: 0
    }

    to {
        left: 0px;
        opacity: 1
    }
}

@keyframes animatebottom {
    from {
        left: -100px;
        opacity: 0
    }

    to {
        left: 0;
        opacity: 1
    }
}