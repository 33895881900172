.header {
    font-size: 40px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.form_container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}

.form_container input[type=text],
.form_container input[type=email],
.form_container .validity,
.form_container select {
    width: 100%;
    padding: 12px 20px;
    margin: 0 0 15px  0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form_container input[type=submit] {
    width: 100%;
    background-color: var(--color-primary-used-100);
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form_container input[type=submit]:hover {
    background-color: var(--color-primary-used-300);
}

.validity input[type=date] {
    padding: 5px;
    margin-right: 15px;
    border-radius: 5px;
}
.validity input[type=time] {
    padding: 5px;
    letter-spacing: 2px;
    border-radius: 5px;
    width: 160px;
}
.validity input {
    cursor: pointer;
}
.validity input[type=time]::-webkit-datetime-edit-fields-wrapper {
    display: flex;
}
.validity input[type=time]::-webkit-datetime-edit-text {
    padding: 2px;
}
/* Hour */
.validity input[type=time]::-webkit-datetime-edit-hour-field {
    /* background-color: #f2f4f5; */
    border-radius: 15%;
    padding: 2px;
}
/* Minute */
.validity input[type=time]::-webkit-datetime-edit-minute-field {
    /* background-color: #f2f4f5; */
    border-radius: 15%;
    padding: 2px;
}
/* AM/PM */
.validity input[type=time]::-webkit-datetime-edit-ampm-field {
    background-color: #7155d3;
    border-radius: 15%;
    color: #fff;
    padding: 2px;
}
/* 'X' button for resetting/clearing time */
.validity input[type=time]::-webkit-clear-button {
    display: none;
}
/* Up/Down arrows for incrementing/decrementing the value */
.validity input[type=time]::-webkit-inner-spin-button {
    display: none;
}


/* Add animation to "page content" */
.animate_bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}
@-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
}
  
@keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
}