.toggle_views {
    margin-top: 40px;
}

.toggle_views .title {
    font-size: large;
    height: 3rem;
    width: 7.5rem;
    padding: 8px ;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    background-color: var(--color-primary-used-100);
}
.toggle_views .desktop,
.toggle_views .mobile {
    background: white;
    font-size: large;
    height: 3rem;
    padding: 8px ;
    border-top: 1px solid;
    border-bottom: 1px solid;
    width: 20rem;
    cursor: pointer;
    margin-left: 5px;
}
.toggle_views .mobile{
    border-right: 1px solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.toggle_views .desktop:hover,
.toggle_views .mobile:hover {
    background: var(--color-primary-used-100);
    color: white;
}
.active_view {
    background: var(--color-primary-used-100) !important;
    color: white;
}

.actions span{
    font-size: large;
    height: 3rem;
    width: 7.5rem;
    padding: 8px ;
    border: 1px solid;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
}
.actions span svg {
    padding-left: 10px;
    font-size: larger;
}
.actions span:hover {
    background: var(--color-primary-used-100) !important;
    color: white;
}
