/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
} */
.wrapper{
  height: 100vh;
  min-height: 200vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 10px;
  /* background: linear-gradient(135deg, #71b7e6, #9b59b6); */
  /* background: linear-gradient(-45deg, #0052d4 0%, #4364f7 35%, #6fb1fc 100%); */
  background: var(--color-primary-used-300);
}
.container{
  max-width: 700px;
  width: 100%;
  max-height: 600px;
  margin-top: 10px;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}
.container .title{
  font-size: 25px;
  font-weight: 500;
  text-align: left;
  position: relative;
  color: var(--color-primary-used-300);
}
.container .title::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  /* background: linear-gradient(135deg, #71b7e6, #9b59b6); */
  background: linear-gradient(-45deg, #0052d4 0%, #4364f7 35%, #6fb1fc 100%);
}
.content form .user_details{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
form .user_details .input_box{
  margin-bottom: 15px;
  width: calc(100% / 2 - 20px);
}
form .input_box span.details{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
}
.user_details .input_box input{
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.user_details .input_box input:focus,
.user_details .input_box input:valid{
  /* border-color: #9b59b6; */
  border-color: #0052d4;
}
 form .gender-details .messages{
  font-size: 20px;
  font-weight: 500;
 }
 form .category{
   display: flex;
   width: 80%;
   margin: 14px 0 ;
   justify-content: space-between;
 }
 form .category label{
   display: flex;
   align-items: center;
   cursor: pointer;
 }


 form input[type="radio"]{
   display: none;
 }
 form .button{
   height: 45px;
   margin: 35px 0
 }
 form .button input{
   height: 100%;
   width: 100%;
   border-radius: 5px;
   border: none;
   color: #fff;
   font-size: 18px;
   font-weight: 500;
   letter-spacing: 1px;
   cursor: pointer;
   transition: all 0.3s ease;
   /* background: linear-gradient(135deg, #71b7e6, #9b59b6); */
   /* background: linear-gradient(-45deg, #0052d4 0%, #4364f7 35%, #6fb1fc 100%); */
   background: var(--color-primary-used-100);
 }
 form .button input:hover{
  /* transform: scale(0.99); */
  /* background: linear-gradient(-135deg, #71b7e6, #9b59b6); */
    /* background: linear-gradient(-45deg, #0052d4 0%, #4364f7 35%, #6fb1fc 100%); */
    background: var(--color-primary-used-300);
  }
 @media(max-width: 584px){
 .container{
  max-width: 100%;
}
form .user_details .input_box{
    margin-bottom: 15px;
    width: 100%;
  }
  form .category{
    width: 100%;
  }
  .content form .user_details{
    max-height: 300px;
    overflow-y: scroll;
  }
  .user_details::-webkit-scrollbar{
    width: 5px;
  }
  }
  @media(max-width: 459px){
  .container .content .category{
    flex-direction: column;
  }
}
