@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Helvetica Neue', 'Verdana';
}

h2 {
  color: var(--color-primary-used-300);
}

body {

  --color-grey-50: hsl(229, 65%, 97%);
  --color-grey-100: hsl(229, 16%, 86%);
  --color-grey-200: hsl(228, 8%, 74%);
  --color-grey-300: hsl(227, 5%, 67%);
  --color-grey-400: hsl(233, 4%, 54%);
  --color-grey-500: hsl(233, 3%, 47%);
  --color-grey-600: hsl(223, 3%, 42%);
  --color-grey-700: hsl(225, 2%, 33%);
  --color-grey-800: hsl(220, 4%, 26%);
  --color-grey-900: hsl(230, 8%, 14%);
/* 00 60 113 */
  --color-primary-50: #b9cada;
  --color-primary-100: #4a7ca8;
  --color-primary-200: #306696;
  --color-primary-300: #20588a;
  --color-primary-400: #0f4a7e;
  --color-primary-500: #084377;
  --color-primary-600: #013d72;
  --color-primary-700: #002c52;

  /* --color-primary-used-100: #6fb1fc; */
  --color-primary-used-100: #5ABFC1;
  --color-primary-used-200: #4364f7;
  /* --color-primary-used-300: #0052d4; */
  --color-primary-used-300: #1E396B;
  --color-grey-used: #f2f2f2;

  --color-success-100: #a2f0bc;
  --color-success-500: #12bd4b;

  --color-error-100: #f1acc9;
  --color-error-500: #a10c4a;

  --size-1: 0.25rem;
  --size-2: 0.5rem;
  --size-3: 0.75rem;
  --size-4: 1rem;
  --size-5: 1.25rem;
  --size-6: 1.5rem;
  --size-8: 2rem;
  --size-16: 4rem;
  --size-20: 5rem;
  --size-40: 10rem;

  /* background: linear-gradient(-45deg, #0052d4 0%, #4364f7 35%, #6fb1fc 100%); */
  background: #1E396B;
  /* background: linear-gradient(-45deg, #667db6 0%, #0082c8 50%,  #0082c8 50%, #667db6 100%); */
}
