.nav {
  display: flex;
  height: 80px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 50px;
  flex-wrap: wrap;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.nav .logo {
  /* color: #fff; */
  color: var(--color-primary-used-300);
  font-size: 35px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.nav .logo img {
  margin-top: 0rem;
  max-width: 5rem;
  max-height: 2.5rem;
}

.nav .ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.nav .ul li {
  margin: 0 5px;
}

.nav .ul li a {
  position: relative;
  /* color: #f2f2f2; */
  color: var(--color-primary-used-300);
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}
.badge {
  /* background-color: #cc2062; */
  /* background-color: blue; */
  background-color: #1E396B;
  color: white;
  border-radius: 12px;
  padding: 0 0.5rem;
  margin-left: 0.3rem;
}
.nav .ul li a:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 0%;
  /* background: #34efdf; */
  background: var(--color-primary-used-300);
  border-radius: 12px;
  transition: all 0.4s ease;
}
.nav .ul li a.active:before {
  width: 100%;
}
.nav .ul li a:hover:before {
  width: 100%;
}

.nav .ul li a.active,
.nav .ul li a:hover {
  /* color: #111; */
  /* background: #fff; */
  color: #fff;
  background: var(--color-primary-used-100);
}

.nav .menu_btn svg {
  /* color: #fff; */
  color: var(--color-primary-used-300);
  font-size: 22px;
  cursor: pointer;
  display: none;
}
.nav .menu_btn .close {
  /* color: #fff; */
  color: var(--color-primary-used-300);
  font-size: 22px;
  cursor: pointer;
  display: none;
}

.nav .input[type="checkbox"] {
  display: none;
}

@media (max-width: 1000px) {
  .nav {
    padding: 0 40px 0 50px;
  }
}

@media (max-width: 920px) {
  .nav .menu_btn svg {
    display: block;
  }

  .click:checked~.menu_btn svg {
    display: none;
  }
  .click:checked~.menu_btn .close {
    display: block;
  }

  .nav .ul {
    position: fixed;
    top: 80px;
    left: -100%;
    /* background: #111; */
    background: var(--color-primary-used-300);
    height: 100vh;
    width: 100%;
    text-align: center;
    display: block;
    transition: all 0.3s ease;
  }

  .click:checked~.ul {
    left: 0;
  }

  .nav .ul li {
    width: 100%;
    margin: 40px 0;
  }

  .nav .ul li a {
    width: 100%;
    margin-left: -100%;
    display: block;
    font-size: 20px;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .click:checked~.ul li a {
    margin-left: 0px;
  }

  .nav .ul li a.active,
  .nav .ul li a:hover {
    background: none;
    /* color: cyan; */
    color: var(--color-primary-used-300);
  }
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: -1;
  width: 100%;
  padding: 0 30px;
  color: #1b1b1b;
}

.content div {
  font-size: 40px;
  font-weight: 700;
}




/* .header {
  width: 100%;
  height: 5rem;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}

.logo {
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  color: var(--color-primary-600);
  margin: 0;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin: 0 1rem;
}

.header a {
  text-decoration: none;
  color: var(--color-primary-600);
  font-weight: bold;
}

.header button {
  font: inherit;
  background-color: transparent;
  border: 1px solid var(--color-primary-600);;
  color: var(--color-primary-600);;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.header a:hover {
  color: var(--color-primary-200);
}

.header button:hover {
  background-color: var(--color-primary-200);
  color: var(--color-primary-400);
} */