.main {
    margin: 3rem auto;
    /* width: 90%; */
    /* max-width: 40rem; */
    /*  */
    width: 90vw;
    margin: 0 auto;
    text-align: center;
    /* padding: 30px; */
    /* background: #f2f2f2; */
    background: var(--color-grey-used);
    min-height: 200vh;
    height: 100%;
  }
