.header {
    font-size: 40px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* .packWrapper {
    margin-bottom: 1rem;
    border: 1px solid;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pack {
    display: flex;
    align-items: center;
    background-color: var(--color-primary-used-100);
    margin-left: 2rem;
    border-radius: 10px;
}
.badge {
    background-color: var(--color-primary-used-100);
    color: white;
    border-radius: 12px;
    padding: 0 0.5rem;
    margin-left: 0.1rem;
    font-size: xx-large;
    display: inline-block;
}
.packWrapper svg {
    font-size: 2.5rem;
    color: black;
    background-color: white;
    margin-left: 1rem;
    display: inline-block;
}

.decreasebtn,
.increasebtn,
.packcount {
    font-size: 1rem;
}
.increasebtn, 
.decreasebtn {
    cursor: pointer;
    font-weight: bolder;
    color: var(--color-primary-used-100);
    background-color: aliceblue;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
}
.total {
    margin-left: 2.5rem;
    font-weight: bolder;
}
.price {
    margin-right: 2.5rem;
}
.decreasebtn:hover {
    color: white;
    background-color: var(--color-primary-used-100);
}
.increasebtn:hover {
    color: white;
    background-color: var(--color-primary-used-100);
}
.decreasebtn {
    margin-left: 2rem;
    margin-right: 0.5rem;
}
.increasebtn {
    margin-left: 0.5rem;
} */


.wrapper {
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart {
    width: 400px;
    height: min-content;
    /* padding: 34px; */
    border-radius: 4px;
    background: var(--background-color);
}

.cart .cart_item {
    position: relative;
    display: flex;
    width: 100%;
    margin: 30px 0;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 18px #2a2f430b;
}

.cart .cart_item .image {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cart .cart_item img {
    width: 90%;
}

.cart .cart_item .info {
    width: 70%;
    padding: 14px;
}

.cart .cart_item h4 {
    color: var(--primary-color);
    font: 700 20px 'Poppins';
}

.cart .cart_item .details {
    display: flex;
}

.cart .cart_item .details i {
    color: #2a2f43d2;
    font-size: 17px;
    transform: translateY(3px);
}

.cart .cart_item .details span {
    color: #2a2f4399;
    font: 600 12px 'Poppins';
    margin-left: 1rem;
}

.cart .cart_item .status {
    margin-left: 8px;
}

.cart .cart_item .price {
    color: var(--secondary-color);
    font: 700 18px 'Poppins';
    margin-top: 8px;
}

.cart .cart_item .counter {
    position: absolute;
    right: 14px;
    bottom: 0;
}

.cart .cart_item .counter svg {
    cursor: pointer;
    width: 24px;
    height: 38px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background: var(--color-primary-used-300);
    border-radius: 8px 8px 0 0;
}

.cart .cart_item .counter svg:hover {
    background-color: var(--color-primary-used-100);
}

.cart .cart_item .counter span {
    margin: 0 6px;
    font: 700 20px 'Poppins';
    color: var(--secondary-color);
}

.cart .pay {
    cursor: pointer;
    width: 100%;
    padding: 16px 0;
    margin: 10px 0 0 0;
    border-radius: 8px;
    font: 500 18px 'Poppins';
    color: #fff;
    background: var(--color-primary-used-300);
    transition: .3s;
}
.cart .pay .nbqrcodes {
    margin-left: 1rem;
    font: 500 14px 'Poppins';
}

.cart .pay:hover {
    background: var(--color-primary-used-100);
}







/* Add animation to "page content" */
.animate_left {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}
@-webkit-keyframes animatebottom {
    from { left:-100px; opacity:0 } 
    to { left:0px; opacity:1 }
}
  
@keyframes animatebottom { 
    from{ left:-100px; opacity:0 } 
    to{ left:0; opacity:1 }
}