
.modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;
    padding: 0.2rem;
    height: 30rem;
    width: 30rem;
    z-index: 10;
    position: fixed;
    top: calc(50% - 15rem);
    left: calc(50% - 15rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.modal input{
    font-size: large;
    border-radius: 5px;
    padding: 0.1rem;
    width: 100%;
    margin-bottom: 2rem;
}
.modal svg {
    position: absolute;
    font-size: xx-large;
    top: 8px;
    right: 10px;
    cursor: pointer;
}
.modal svg:hover {
    color: #8b8989;
}
.modal button {
    font-size: large;
    border-radius: 5px;
    padding: 0.3rem;
    width: 100%;
    margin-left: 1rem;
    cursor: pointer;
}

.backdrop {
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}