

.wrapper .part {
    background: #f2f2f2;
    /* width: calc(33% - 20px); */
    padding: 30px 30px;
    position: relative;
    /* box-shadow: 0 5px 10px rgba(0,0,0,0.1); */
}
.part .img_section{
    display: flex;
    justify-content: center; 
}
.part .img_area {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    padding: 2px;
    background-color: var(--color-primary-used-200);
}
.img_area .inner_area {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 5px solid white;
    line-height: 117px;
    text-align: center;
    color: white;
    position: relative;
    background-color: var(--color-primary-used-200);
    display: flex;
    justify-content: center;
    align-items: center;
}
.inner_area svg {
    font-size: 75px;
    font-weight: 500;
}
.inner_area .img {
    font-size: 35px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.inner_area .img img {
    margin-top: 0rem;
    max-width: 5rem;
    max-height: 4rem;
    border-radius: 10px;
}

.part .part_name {
    width: 100%;
    height: 2px;
    margin: 35px 0;
    position: relative;
    background: var(--color-primary-used-200);
}
.part .part_name::before{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 25px;
    font-weight: 500;
    background: #f2f2f2;
    padding: 0 15px;
    transform: translate(-50%, -50%);
}
.profile .part_name::before {
    content: "Profile";
}
.atrium .part_name::before {
    content: "Atrium";
}
.template .part_name::before {
    content: "Email Template";
}


/* Add animation to "page content" */
.animate_bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
}
  
@keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
}