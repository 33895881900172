.content {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin: 20px;
    padding: 20px;
    /* border: 2px solid black; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 6px;
    background: #fff;
    transition: all .3s ease;
}

.content .fab {
    font-size: 70px;
    margin: 16px 0;
    color: var(--color-primary-used-300);
}

.content>* {
    flex: 1 1 100%;
}

.content:hover {
    /* color: white; */
    border-color: var(--color-grey-used);
    background: var(--color-grey-used);
}

/* .content:hover button {
    border-color: white;
    background: var(--color-error-300);
    color: var(--color-error-500);
    cursor: pointer;
} */
/* .content:hover .content h2{
    color: #fff;
} */

.content h2 {
    font-size: 15px;
    margin: 0;
    letter-spacing: 1px;
    /* text-transform: uppercase; */
    /* color: var(--color-grey-700); */
}
/* .content:hover h2{
    color: #fff;
} */

.content ul {
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    list-style: none;

}
.content ul li {
    display: flex;
    justify-content: space-between;
}
.content ul li span {
    font-weight: bold;
    color: var(--color-grey-600);
}
/* .content:hover ul li span{
    color: #fff;
} */

.content button {
    margin: 22px 0;
    background: var(--color-primary-used-100);
    color: var(--color-error-500);
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid var(--color-primary-used-100);
    padding: 15px 0;
    border-radius: 25px;
    transition: .3s ease;
    cursor: pointer;
}

.content button:hover {
    border-radius: 4px;
    background-color: var(--color-primary-used-300);
}

/* *********************************** */
.user_name {
    width: 100%;
    height: 2px;
    margin: 5px 0 30px 0;
    position: relative;
    background: var(--color-primary-used-300);
}

.user_name::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 25px;
    font-weight: 500;
    background: #fff;
    padding: 0 15px;
    transform: translate(-50%, -50%);
}
