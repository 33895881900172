.header {
    font-size: 40px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.search_field {
    border-radius: 2px;
    font-size: medium;
}
.search_field input{
    padding: 8px;
    border: none;
    text-align: center;
    font-size: large;
}
.search_field svg {
    font-size: xx-large;
    vertical-align: middle;
    padding: 6px 10px;
}
.list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@media (max-width: 900px) {
    .list {
        display: flex;
        flex-direction: column;
    }
}

/* Add animation to "page content" */
.animate_bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}
@-webkit-keyframes animatebottom {
    from { left:-100px; opacity:0 } 
    to { left:0px; opacity:1 }
}
  
@keyframes animatebottom { 
    from{ left:-100px; opacity:0 } 
    to{ left:0; opacity:1 }
}