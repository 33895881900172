.header {
    font-size: 40px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.badge {
    /* background-color: #cc2062; */
    background-color: var(--color-primary-used-300);
    color: white;
    border-radius: 12px;
    padding: 0 0.5rem;
    margin-left: 0.3rem;
    font-size: xx-large;
}
.badgeWrapper svg {
    font-size: xx-large;
    color: black;
    background-color: white;
}
.button{
    width: 80%;
    background-color: var(--color-primary-used-100);
    color: white;
    font-size: larger;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;

}
.button:hover {
    background-color: var(--color-primary-used-300);
}


/* Add animation to "page content" */
.animate_bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
}
  
@keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
}