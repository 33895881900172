.card {
    /* border-bottom: 1px solid var(--color-grey-300); */
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card .title {
    font-size: large;
    height: 3rem;
    width: 7.5rem;
    padding: 8px 2px 1px 5px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}
.card .texte {
    background: white;
    font-size: large;
    height: 3rem;
    padding: 8px 2px 1px 5px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    width: 20rem;
}
.card button {
    font-size: 1.5rem;
    padding: 5px;
    cursor: pointer;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}